
<template>
  <div id="ooh_amap">
    <div
      id="container"
      :style="'height:'+height+'px'"
    ></div>
    <div
      v-if="isSearch"
      id="myPageTop"
    >
      <div>
        <input
          id="tipinput"
          placeholder="请输入关键字，并选择地址"
        />
      </div>
    </div>
  </div>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader'

export default {
  name: 'map-view',
  props: {
    height: {
      type: Number,
      default: 800
    },
    isSearch: {
      type: Boolean,
      default: false
    },
    funGetPoi: {
      type: Function
    }
  },
  data () {
    return {
      map: null
    }
  },
  mounted () {
    this.initAMap()
  },
  unmounted () {
    this.map.destroy()
  },
  methods: {
    initAMap () {
      const that = this
      AMapLoader.reset()

      window._AMapSecurityConfig = {
        securityJsCode: '82e0eebe0b1d169a209f0774e5883109'
      }
      AMapLoader.load({
        key: 'e16ba0772f4949f693a8ab84b44b0fab', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.Scale'] // 需要使用的的插件列表，如比例尺'AMap.Scale'，支持添加多个如：['...','...']
      })
        .then((AMap) => {
          const map = new AMap.Map('container', {
            // 设置地图容器id
            resizeEnable: true,
            zoom: 11 // 初始化地图级别
            // center: [116.397428, 39.90923] // 初始化地图中心点位置
          })
          that.map = map

          // 是否进行地址搜索
          if (that.isSearch) {
            // 输入提示
            var autoOptions = {
              input: 'tipinput'
            }
            // 加载输入提示插件
            AMap.plugin(['AMap.PlaceSearch', 'AMap.AutoComplete'], function () {
              var auto = new AMap.AutoComplete(autoOptions)
              var placeSearch = new AMap.PlaceSearch({
                map: map
              })
              auto.on('select', function (e) {
                // 父组件传递了获取位置数据的方法，则调用
                if (that.funGetPoi) {
                  that.funGetPoi(e.poi)
                }

                placeSearch.setCity(e.poi.adcode)
                placeSearch.search(e.poi.name) // 关键字查询查询
              })// 注册监听，当选中某条记录时会触发
            })
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    /**
   * 切换城市地图
   * @param cityName 城市名
   */
    onChangeCityMap (cityName = '') {
      if (cityName.trim().length) {
        this.map.setCity(cityName)
      }
    },
    /**
     * 根据关键字在地图上搜索
     * @param cityName 城市名称
     * @param keywords 搜索关键字
     */
    onSearchAddress (cityName, keywords = '') {
      const that = this
      AMap.plugin(['AMap.PlaceSearch'], function () {
        // 构造地点查询类
        var placeSearch = new AMap.PlaceSearch({
          city: cityName, // 兴趣点城市
          citylimit: true, // 是否强制限制在设置的城市内搜索
          map: that.map, // 展现结果的地图实例
          // panel: 'panel', // 结果列表将在此容器中进行展示。
          autoFitView: true // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
        })
        // 关键字查询
        placeSearch.search(keywords)
      })
    }
  }
}
</script>
<style>
#ooh_amap {
  position: relative;
}
#container {
  width: 100%;
  color: #333;
}
#myPageTop {
  position: absolute;
  color: #333;
  top: 10px;
  left: 10px;
}
#tipinput {
  width: 200px;
}
.amap-sug-result {
  z-index: 9999;
  color: #333;
}
</style>
